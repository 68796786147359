/**
 * External dependencies
 */
import React, { useState, useEffect, useMemo } from "react"
import axios from "axios";

import { Link } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"
import { currencyFormat } from '@components/common/utils';
/**
 * Internal dependencies
 */
 import {
  isIOS,
  isMobile
} from "react-device-detect";
import useHomeProperties from "./use-home-properties"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import Card from "../card/card"
import LinkIcon from "../link-icon/link-icon"
import properties from "./static/properties"
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
import "./styles/_index.scss"
import Carousel from "../carousel/carousel"
import classNames from "classnames"
import {
  FOR_SALE_PAGE_URL,
  TO_RENT_PAGE_URL,
  GUIDE_PAGE_URL
} from "@components/common/site/constants"
import Modal from 'react-bootstrap/Modal';
import BookAViewingForm from "../../Forms/BookAViewing"
const AreaGuideSlider = (props) => {
  // const data = useHomeProperties()
  // const images = useMemo(() => convertGatsbyImageNodes(data), [data])
  const propertiesCollection = useMemo(
    () =>
      props?.data?.map((x, i) => {
        let processedImages = JSON.stringify({})
          if (x.imagetransforms?.Tile_Image_Transforms) {
            processedImages = x.imagetransforms.Tile_Image_Transforms
          }

        return (
          <Col
          key={x.Title}
          className={classNames("news-land__col", {
            // "d-none d-lg-block": index >= 2,
          })}
        >
          <Card className="card-land">
    
          <Card.Head
          onImageHoverZoom
            image={x.Tile_Image}
            video={x.Video_Url}
            playBtnOnRight={x.Video_Url ? true : false}
            alt={x.Tile_Image?.alternativeText?x.Tile_Image?.alternativeText:"Area Guide - Strakers Estate Agents"}
            href={GUIDE_PAGE_URL.alias+'/'+x.URL+"/"}
            imgname={"area-guide.Tile_Image.list"} imagetransforms={processedImages} id={x.id}
          />
          <Card.Body>
            <Row className="g-0 align-items-center">
              <Col>
                <h5>
                  <Link to={GUIDE_PAGE_URL.alias+'/'+x.URL}>{x.Title}</Link>
                </h5>
              </Col>
              
              <Col xs="auto">
                <Link to={GUIDE_PAGE_URL.alias+'/'+x.URL} className="link-icon blue-link-hover d-flex align-items-center">
                <div
            className={`link-icon__text fw-bolder fz14 position-relative order-0`}
          >
            View Guide
          </div>
          <i className={classNames(`icon icon--arrow-up-right`)}></i>
                </Link>
                {/* <LinkIcon text="View Guide" /> */}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        </Col>
        )
      }),
    [props?.data]
  )

  return (
    props?.data?.length > 0 &&
    <div className="home-properties area-guide pt-48 pb-48 pt-120 pb-120">
      <Container className="home-properties__inner">
        {props.Cap_Title &&<h6 className="home-properties__quote fw-bolder fz14 d-inline-block">
          {props.Cap_Title}
        </h6>
    }

        <Row className="align-items-center align-items-lg-start justify-content-lg-between">
          <Col xs={12} lg="auto">
            <h3>{props.Title}</h3>
          </Col>

          <Col lg="auto">
            <Row className="flex-nowrap home-properties__actions">
              <Col xs="auto" className="home-properties__action-col-1">
                <Link class="link-icon blue-link-hover d-flex align-items-center"
                  to={GUIDE_PAGE_URL?.alias}
                >
                  <div
                    className={`link-icon__text fw-bolder fz14 position-relative order-0`}
                  >
                    View Area Guides
                  </div>
                  <i className={classNames(`icon icon--arrow-up-right`)}></i>
                </Link>
                {/* <LinkIcon href="/" text="Property for sale" /> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {/* {JSON.stringify(propItems)} */}
      <div className="news-land">
      {props?.data?.length > 0 &&
      <Carousel
        className="news-land__row flex-column flex-md-row"
        data={propertiesCollection}
        controllers={{ buttons: isMobile && props?.data?.length > 1 ? true : !isMobile && props?.data?.length > 3 ? true : false }}
        isFluidContainer={false}
      />}
      </div>
      
       
    </div>
  )
}

export default AreaGuideSlider
