/**
 * External dependencies
 */
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import HiddenText from "../hidden-text/hidden-text"
import LocRatingMap from '../map/loc-rating-map';
/**
 * Internal dependencies
 */
import PropertyCallout from "../property-callout/property-callout"
import PropertyIntro from "../property-intro/property-intro"
import PropertySchema from "../property-schema/property-schema"
import PropertyCalculator from "../property-calculator/property-calculator"

import Accordions from "../accordion/accordion"
import usePropertyInfo from "./use-property-info"
import "./styles/_index.scss"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import ContentBlock from "../content/Content";
import CalloutBanner from "../callout-banner/callout-banner";
import { sitename } from "@constants";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  // InstapaperShareButton,
  WhatsappShareButton
} from "react-share";
import Stack from 'react-bootstrap/Stack';
import { StarberryIcons } from "@components/icons/index";
// temporary data place to build UI
const data = {
  intro: {
    price: "975,000",
    street: "Old Loves Farm",
    village: "Bowerhill",
    description: "5 bedroom detached house for sale",
  },
  schema: {
    image: "",
    bullets: [
      {
        icon: "bed",
        title: "Bedrooms:",
        description: 4,
      },
      {
        icon: "bath",
        title: "Bathrooms:",
        description: 3,
      },
      {
        icon: "chair",
        title: "Reception Rooms:",
        description: "4",
      },
      {
        icon: "sheet",
        title: "Tenure:",
        description: "Freehold",
      },
      {
        icon: "house",
        title: "Property Type:",
        description: "House",
      },
    ],
  },
  features: [
    [
      "Grade II Listed Farmhouse",
      "5 Bedrooms, Bathroom & Ensuite",
      "4 Reception Rooms",
      "1.35 Acres In Total",
      "Well Maintained Gardens",
    ],
    [
      "Large Yard, Garaging, Parking",
      "Excellent Range Of Outbuildings",
      "Good Commercial Rental Opportunity",
      "Rural Setting On The Edge of Town",
    ],
  ],
  description: {
    text: `Old Loves Farm is a fabulous Grade II Listed farm house believed to date from the early/mid 18th century and set in well maintained gardens. The property has a small paddock to the rear as well as featuring a large yard with a separate access and an array of quality barns and outbuildings. Internally, the spacious well laid out interior features a good sized modern kitchen/breakfast room with 'Neff' appliances and tiled flooring, a dual aspect family room with an Inglenook fireplace and exposed beams, a study, a sitting room with an inviting log burning stove for those cold evenings and a separate dual aspect dining room. Old Loves Farm is a fabulous Grade II Listed farm house believed to date from the early/mid 18th century and set in well maintained gardens. The property has a small paddock to the rear as well as featuring a large yard with a separate access and an array of quality barns and outbuildings. Internally, the spacious well laid out interior features a good sized modern kitchen/breakfast room with 'Neff' appliances and tiled flooring, a dual aspect family room with an Inglenook fireplace and exposed beams, a study, a sitting room with an inviting log burning stove for those cold evenings and a separate dual aspect dining room.`,
  },
  accordions: [
    {
      title: "Favourite bits",
      text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: "About the Location",
      text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
    {
      title: "Important Information",
      text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`,
    },
  ],
  callout: {
    town: "Chippenham",
    street: "33/34 Market Place, SN15 3HP",
  },
}

const BranchInfo = (props) => {
  const queryData = usePropertyInfo()
  const images = convertGatsbyImageNodes(queryData)
  let mapItems = [];
                let mapItem = {};
                mapItem['lat']  = props?.data?.Latitude
                mapItem['lng']  = props?.data?.Longitude
                mapItem['id']  = props?.data?.id
                const mapMarkerImageShowFlag = 0;
                mapItems.push( mapItem );

                const shareurl = typeof window !== 'undefined' ? window.location.href : ''
                const trackerShare = (event, scl_share_url) => {
                  var shareURL = typeof window !== 'undefined' ? window.location.href : ''
                  if (scl_share_url) {
                      window.open(scl_share_url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600')
                  }
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                      'event': 'Share - social icons',
                      'formType': event + ' - ' + shareURL,
                      'formId': 'Share',
                      'formName': 'Share',
                      'formLabel': 'Share'
                  });
              }
              
  return (
    <div className="property-info-wrapper branch">
      <Container className="property-info">
      <Row className="property-info__callout row-view d-block d-md-none">
          
          {props?.data?.Gallery_Images?.map((item, i) => {
              let processedImages = JSON.stringify({});
              if (props.data?.imagetransforms?.Image_Transforms) {
                  processedImages = props.data.imagetransforms.Image_Transforms;
              }
            return(
              i === 0 ?
              <Col md="6">
              <div className="gallery-img mob-view">
              <ImageTransform
                      imagesources={item?.Image?.url}
                      renderer="srcSet"
                      imagename="office.Image.gallery"
                      attr={{ alt: `${props.data?.Office_Name} - ${sitename}`, class:'' }}
                      imagetransformresult={processedImages}
                      id={props.data?.id}
                    />
                    {/* <img src={item?.Image?.url} alt={item?.Image?.alternativeText} /> */}
              </div>  
              </Col>    
              : null          
            )
          })}
          
          
                    </Row>
        <Row className="flex-column flex-lg-row justify-content-lg-between">
          <Col className="property-info__content none-border-botttom" lg="auto">
          <h5>Opening Hours</h5>
          <ContentBlock Content={props?.data?.Opening_Hours} />
          <h5 className="share">Share</h5>
            <Stack direction="horizontal" className="social-share-btns">
                    <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                        <StarberryIcons iconName="facebookIcon" className="bi" />
                    </FacebookShareButton>
                    <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                        <StarberryIcons iconName="twitterIcon" className="bi" />
                    </TwitterShareButton>
                    <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                        <StarberryIcons iconName="linkedInIcon" className="bi" />
                    </LinkedinShareButton>
                   
                </Stack>
            <HiddenText
              title="About"
              text={props?.data?.About_Content}
            />

            {/* <Accordions>
              {data.accordions.map((x, index) => (
                <Accordions.Item key={x.title} eventKey={index} title={x.title}>
                  <p>{x.text}</p>
                </Accordions.Item>
              ))}
            </Accordions> */}
             <Row className="property-info__callout row-view d-none d-md-block d-lg-none">
          
          {props?.data?.Gallery_Images?.map((item, i) => {
              let processedImages = JSON.stringify({});
              if (props.data?.imagetransforms?.Image_Transforms) {
                  processedImages = props.data.imagetransforms.Image_Transforms;
              }
            return(
              <Col md="6">
              <div className="gallery-img">
              <ImageTransform
                      imagesources={item?.Image?.url}
                      renderer="srcSet"
                      imagename="office.Image.gallery"
                      attr={{ alt: `${props.data?.Office_Name} - ${sitename}`, class:'' }}
                      imagetransformresult={processedImages}
                      id={props.data?.id}
                    />
                    {/* <img src={item?.Image?.url} alt={item?.Image?.alternativeText} /> */}
              </div>  
              </Col>              
            )
          })}
          
          
                    </Row>
            {props?.data?.Latitude && props?.data?.Longitude &&
            <div className="property-details-landing-map my-5" id="property-details-map">
                                  <LocRatingMap data={mapItems} hasTitle={true} />
                              </div>
}
          </Col>

          <Col className="property-info__callout d-none d-lg-block" lg="auto">
          
{props?.data?.Gallery_Images?.map((item, i) => {
    let processedImages = JSON.stringify({});
    if (props.data?.imagetransforms?.Image_Transforms) {
        processedImages = props.data.imagetransforms.Image_Transforms;
    }
  return(
    <div className="gallery-img">
    <ImageTransform
            imagesources={item?.Image?.url}
            renderer="srcSet"
            imagename="office.Image.gallery"
            attr={{ alt: `${props.data?.Office_Name} - ${sitename}`, class:'' }}
            imagetransformresult={processedImages}
            id={props.data?.id}
          />
          {/* <img src={item?.Image?.url} alt={item?.Image?.alternativeText} /> */}
    </div>                
  )
})}


          </Col>
        </Row>

      </Container>
    </div>
  )
}

export default BranchInfo
