/**
 * External dependencies
 */
 import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap"
import classNames from "classnames"

/**
 * Internal dependencies
 */
import sales from "./static/sales"
import CardSale from "./card-sale"
import useNewsSales from "./use-news-sales"
import Button from "../button/button"
import { convertGatsbyImageNodes } from "../../utils/gatsby-images"
import "./styles/_index.scss"
import { useStaticQuery, graphql } from "gatsby";
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import {
  TEAM_PAGE_URL
} from "../common/site/constants"
import {
  isIOS,
  isMobile
} from "react-device-detect";
import Carousel from "../carousel/carousel"
const BranchNewsSales = (props) => {
  const propertiesCollection = useMemo(
    () =>
    props?.teams?.map((x, index) => {
       
      let processedImages = JSON.stringify({})
      if (x.imagetransforms?.Image_Transforms) {
        processedImages = x.imagetransforms.Image_Transforms
      }
        return (
          <Col
            key={index}
            className={classNames("news-sales__col")}
          >
            <CardSale
            imagetransforms={processedImages}
            id={x.id}
              image={x.Image}
              video={x.Video_Url}
              name={x.Name}
              position={x.Designation}
              href={TEAM_PAGE_URL.alias+'/'+x.URL+'/'}
              index={index}
              agent={x}
              imgname={"team.Image.listsmall"}
            />
          </Col>
        )
      }),
    [props?.teams]
  )
    
  return (
    props?.teams?.length > 0 ?
    <div className="home-news branch pt-24 pb-24  pt-48 pb-48 pt-120 pb-120">
    <Container className="news-sales branch">
      
      <Container>
    <Row className="g-0 align-items-md-center align-items-lg-start">
    <Col>
    <h4>{props?.officename=="Vale of Pewsey" ? "Meet our local expert":"Meet the Team"}</h4>
      </Col>
    </Row>
    </Container>

      {props?.teams?.length > 0 &&
            <Row className="news-sales__row flex-column flex-md-row">

                <Carousel
                className="news-land__row other flex-column flex-md-row"
                data={propertiesCollection}
                controllers={{ buttons: isMobile && props?.teams?.length > 1 ? true : !isMobile && props?.teams?.length > 4 ? true : false }}
                isFluidContainer={false}
              />
              </Row>
        }
        {/* {!isMobile && props?.teams?.length > 0 &&
        
        <Container>
            <Row className="news-sales__row flex-column flex-md-row">
{props?.teams?.map((x, index) => {
        let processedImages = JSON.stringify({})
        if (x.imagetransforms?.Image_Transforms) {
          processedImages = x.imagetransforms.Image_Transforms
        }
  
  return (
    
                <Col
                key={index}
                className={classNames("news-sales__col")}
              >
                <CardSale
                imagetransforms={processedImages}
                id={x.id}
                  image={x.Image}
                  video={x.Video_Url}
                  name={x.Name}
                  position={x.Designation}
                  href={TEAM_PAGE_URL.alias+'/'+x.URL+'/'}
                  index={index}
                  agent={x}
                  imgname={"team.Image.listsmall"}
                />
              </Col>

  )})}
  </Row>
  </Container>
      
} */}
      
    </Container>
    </div>
 : null )
}

export default BranchNewsSales
